<template>
  <div class="date-select">
    <div class="date-week-list">
      <div
        class="date-week-item"
        :class="{ active: item.value === value, disabled: judgeIsSelect(item.value) }"
        v-for="(item, index) in weekList"
        :key="'dateweekitem' + index"
        @click="dateWeekOnClick(item, index)"
      >
        <div class="week-text">{{ item.weekText }}</div>
        <div class="date-text">{{ item.dateText }}</div>
      </div>
    </div>
    <div class="more-date" @click="calendarShow = true">
      <van-icon name="notes-o" />
      更多
    </div>

    <van-calendar
      v-model="calendarShow"
      first-day-of-week="1"
      safe-area-inset-bottom
      :min-date="minDate"
      :max-date="maxDate"
      :default-date="defaultDate"
      color="#FDBF3A"
      @confirm="calendarOnConfirm"
    />
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { Icon, Calendar } from 'vant';

moment.locale('zh-cn');
Vue.use(Icon);
Vue.use(Calendar);

export default {
  name: 'dateSelect',
  props: {
    value: {
      type: [Date, String, Number],
      default: moment().format('YYYY-MM-DD')
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      weekList: [],
      calendarShow: false,
      minDate: new Date(),
      maxDate: new Date(moment().add(1, 'M'))
    };
  },
  computed: {
    defaultDate() {
      return new Date(this.value);
    }
  },
  watch: {},
  created() {
    this.getWeeksList(this.value);
  },
  methods: {
    getWeeksList(value) {
      const curWeekOne = moment(value).startOf('week');
      this.weekList = new Array(7).fill(0).map((it, i) => {
        const valueText = moment(curWeekOne).add(i, 'd').format('YYYY-MM-DD 周dd');
        const value = moment(curWeekOne).add(i, 'd').format(this.format);
        return {
          weekText: moment(value).format('dd'),
          dateText: moment(value).format('M.D'),
          value: value,
          valueText
        };
      });
    },
    dateWeekOnClick(value, index) {
      if (new Date(value.value) < new Date(moment().format(this.format))) return;
      if (!Object.is(value.value, this.value)) {
        this.$emit('change', value.value);
      }
    },
    calendarOnConfirm(value) {
      this.calendarShow = false;
      if (!Object.is(this.value, moment(value).format(this.format))) {
        this.getWeeksList(value);
        this.$emit('change', moment(value).format(this.format));
      }
    },
    judgeIsSelect(value) {
      return new Date(value) < new Date(moment().format(this.format));
    }
  }
};
</script>

<style lang="less" scoped>
.date-select {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 140px;
  border-bottom: 1px solid #f7f7f7;
}
.date-week-list {
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  .date-week-item {
    box-sizing: border-box;
    height: 104px;
    width: calc(100% / 7);
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.85);
    .week-text {
      box-sizing: border-box;
      font-size: 26px;
      line-height: 30px;
    }
    .date-text {
      box-sizing: border-box;
      font-size: 24px;
      line-height: 28px;
      margin-top: 10px;
    }
    &.active {
      background-color: #ffbe00;
      color: #ffffff;
    }
    &.disabled {
      color: #c8c9cc;
      pointer-events: none;
    }
  }
}
.more-date {
  box-sizing: border-box;
  box-shadow: -4px 0px 2px -2px rgba(0, 0, 0, 0.24);
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0px 20px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
}
</style>
