<template>
  <div class="left-right-line-text">
    <div class="line left-line"></div>
    <div class="text">{{ text }}</div>
    <div class="line right-line"></div>
  </div>
</template>

<script>
export default {
  name: 'leftRightLineText',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.left-right-line-text {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .line {
    box-sizing: border-box;
    height: 1px;
    flex-grow: 1;
    background-color: #E0E0E0;
  }
  .text {
    box-sizing: border-box;
    flex-shrink: 0;
    font-size: 28px;
    line-height: 40px;
    color: #584600;
    margin: 0px 20px;
  }
}
</style>