<template>
  <div class="place-order" v-show="isAuth">
    <div class="header-info-box">
      <img class="sq-logo" src="./img/sq_logo.png" />
      <div class="text-info">
        <div class="sq-title">井亭未来社区-预约点餐服务</div>
        <div class="sub-text">
          下单时请确认订单信息，如有异常请联系社区，联系方式： <a href="tel:0574-55711314" style="color: #007eff">0574-55711314</a>
        </div>
      </div>
    </div>
    <date-select ref="date_select" v-model="selectedDate" @change="seledDateOnChange" />
    <div class="type-dish-pac-box">
      <div class="type-box">
        <div class="type-list">
          <div
            class="type-item"
            :class="{ active: selectedType === item.id }"
            v-for="(item, index) in typeList"
            :key="'lx' + index"
            @click="typeOnClick(item, index)"
          >
            <div class="type-name">{{ item.typeName }}</div>
            <div class="ycsd-text">{{ item.ycsdText }}</div>
            <div class="ygdcsd-text" v-if="item.ygdcsdText">{{ item.ygdcsdText }}</div>
          </div>
        </div>
        <div class="my-order-btn" @click="myOrderOnClick"></div>
      </div>
      <div class="dish-pack-box">
        <div class="order-food-time">
          <img class="dcicon" src="./img/dc_icon.png" />
          <div class="label-text">可订时段：</div>
          <div class="value-text">{{ selectedValue.kdsdText }}</div>
        </div>
        <div class="dish-pack-list">
          <left-right-line-text text="特色菜品" v-show="dishList.length > 0" />
          <dish-info
            class="dish-info-item"
            v-for="(item, index) in dishList"
            :key="selectedType + 'tscp' + index"
            :detail="item"
            :quantity.sync="item.quantity"
            @quantity-change="quantityOnChange"
          />
          <left-right-line-text text="精选套餐" v-show="packList.length > 0" />
          <dish-info
            class="pack-info-item"
            v-for="(item, index) in packList"
            :key="selectedType + 'jxtc' + index"
            :detail="item"
            :quantity.sync="item.quantity"
            @quantity-change="quantityOnChange"
          />
        </div>
      </div>
    </div>
    <div class="footer-box">
      <div class="dining-car" @click="dinCarOnClick">
        <div class="din-car-img" :class="{ dis: totalQuantityText <= 0 }">
          <!-- <img class="img" src="./img/meal_bag.png">
          <div class="din-car-number">{{ totalQuantityText }}</div> -->
        </div>
        <div class="price-amount-total">
          <div class="price-text">¥ {{ priceText }}</div>
          <div class="amount-text">
            共 <span class="num-text">{{ totalQuantityText }}</span> 份
          </div>
        </div>
      </div>
      <div class="now-down-order-btn" :class="{ dis: totalQuantityText <= 0 }" @click="nowDownOrderOnClick">现在下单</div>
    </div>

    <van-popup v-model="popupShow" round position="bottom" :overlay-style="{ zIndex: 97 }" class="din-car-popup" safe-area-inset-bottom>
      <div class="popupt-title">
        <div class="title-text">已选餐品</div>
        <div class="clear-text" @click="clearDinCarOnClick"><van-icon name="delete-o" size="20" />清空餐车</div>
      </div>
      <div class="selected-dish-pac-box">
        <div class="selected-dish-pac-list">
          <dish-info
            v-for="(item, index) in dinCarList.filter(item => !item.ygdcsdText)"
            :key="'cc' + index"
            :detail="item"
            :quantity.sync="item.quantity"
            size="small"
            @quantity-change="quantityOnChange"
          />
        </div>
        <div class="sxcp-box" v-if="dinCarList.filter(item => item.ygdcsdText).length > 0">
          <div class="sxcp-title">
            <div class="title-text">失效餐品</div>
            <div class="clear-text" @click="clearSxDish"><van-icon name="delete-o" size="20" />清空失效餐品</div>
          </div>
          <div class="sxcp-list">
            <dish-info
              v-for="(item, index) in dinCarList.filter(item => item.ygdcsdText)"
              :key="'sxcp' + index"
              :detail="item"
              :quantity.sync="item.quantity"
              size="small"
              lose-show
              @quantity-change="quantityOnChange"
            />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import dateSelect from './components/dateSelect.vue';
import leftRightLineText from './components/leftRightLineText.vue';
import dishInfo from './components/dishInfo.vue';
import axios from '@/axios';
import { getPlaceTypeListUrl, getPlaceOrderListUrl, getElderCanteenAuthUrl, userInfoURL } from './api.js';
import { toRegister } from '@/utils/common.js';
import moment from 'moment';
import Big from 'big.js';

moment.locale('zh-cn');

export default {
  name: 'eldCanPlaceOrder',
  components: {
    dateSelect,
    leftRightLineText,
    dishInfo
  },
  data() {
    return {
      firstInit: true,
      isAuth: false,
      selectedDate: void 0,
      selectedType: void 0,
      selectedValue: {},
      typeList: [],
      dishList: [],
      packList: [],
      popupShow: false,
      timer: null,
      currentTime: new Date(),
      dinCarList: []
    };
  },
  computed: {
    priceText() {
      return this.dinCarList
        .filter(item => !item.ygdcsdText)
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prve, next) => Big(prve).plus(next), 0);
    },
    totalQuantityText() {
      return this.dinCarList
        .filter(item => !item.ygdcsdText)
        .map(item => +item.quantity)
        .reduce((prve, next) => Big(prve).plus(next), 0);
    }
  },
  watch: {},
  // async beforeRouteEnter(to, from, next) {
  //   //  await toRegister(this.$route.path, this.$route.query, "热门活动报名")
  //   const userInfoStr = sessionStorage.getItem('userInfo');
  //   const userInfo = userInfoStr && userInfoStr !== 'undefined' ? JSON.parse(userInfoStr) : {};
  //   const userRes = await axios.get(userInfoURL, { params: { userId: userInfo.userId } }).catch(() => {});
  //   userRes.data = userRes.data || {};
  //   const authRes = await axios.get(getElderCanteenAuthUrl, { params: { houseId: userRes.data.chosenSpaceId } }).catch(() => {});
  //   authRes.data === true
  //     ? next()
  //     : next({
  //         name: 'eldCannNoAuth',
  //         replace: true
  //       });
  // },
  async created() {
    if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
      const userInfoStr = sessionStorage.getItem('userInfo');
      const userInfo = userInfoStr && userInfoStr !== 'undefined' ? JSON.parse(userInfoStr) : {};
      const userRes = await axios.get(userInfoURL, { params: { userId: userInfo.userId } }).catch(() => {});
      userRes.data = userRes.data || {};
      const authRes = await axios.get(getElderCanteenAuthUrl, { params: { houseId: userRes.data.chosenSpaceId } }).catch(() => {});
      if (authRes.data === true) {
        this.isAuth = true;
        this.$store.commit('removeNoKeepAlive', 'eldCanPlaceOrder');
        this.selectedDate = moment().format('YYYY-MM-DD');
        this.getList();
      } else {
        this.$router.replace({
          name: 'eldCannNoAuth'
        });
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    getList() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      Promise.all([
        this.$axios.get(getPlaceTypeListUrl, { params: { saleDate: this.selectedDate }, noGlobalLoading: true }),
        this.$axios.get(getPlaceOrderListUrl, { params: { saleDate: this.selectedDate }, noGlobalLoading: true })
      ])
        .then(([typRes, disRes]) => {
          typRes.data = Array.isArray(typRes.data) ? typRes.data : [];
          disRes.data = Array.isArray(disRes.data) ? disRes.data : [];
          typRes.data.reverse();
          disRes.data.forEach(el => {
            el.quantity = 0;
          });
          typRes.data.forEach(item => {
            item.ycsdText =
              item.supplyTimeStart && item.supplyTimeStart ? item.supplyTimeStart.slice(0, 5) + ' ~ ' + item.supplyTimeEnd.slice(0, 5) : '';
            item.kdsdStart =
              item.orderDayFlagStart && item.orderTimeStart
                ? moment(this.selectedDate)
                    .subtract(item.orderDayFlagStart === '0' ? item.orderDaysStart : 0, 'd')
                    .format('YYYY-MM-DD ' + item.orderTimeStart.slice(0, 6) + '00')
                : '';
            item.kdsdEnd =
              item.orderDayFlagEnd && item.orderTimeEnd
                ? moment(this.selectedDate)
                    .subtract(item.orderDayFlagEnd === '0' ? item.orderDaysEnd : 0, 'd')
                    .format('YYYY-MM-DD ' + item.orderTimeEnd.slice(0, 6) + '59')
                : '';
            item.kdsdText =
              item.kdsdStart && item.kdsdEnd
                ? moment(item.kdsdStart).format('M.D HH:mm') + ' ~ ' + moment(item.kdsdEnd).format('M.D HH:mm')
                : '不限时间';
            if (item.kdsdStart && item.kdsdEnd) {
              if (this.currentTime < new Date(item.kdsdStart)) {
                item.ygdcsdText = '未到订餐时段';
              } else if (this.currentTime > new Date(item.kdsdEnd)) {
                item.ygdcsdText = '已过订餐时段';
              } else {
                item.ygdcsdText = '';
              }
            } else {
              item.ygdcsdText = '';
            }
            item.dishList = disRes.data.filter(
              el => el.saleStatus !== 0 && !['', void 0, null, NaN].includes(el.dishId) && el.dishTypeId === item.id
            );
            item.packList = disRes.data.filter(
              el =>
                el.saleStatus !== 0 &&
                ['', void 0, null, NaN].includes(el.dishId) &&
                !['', void 0, null, NaN].includes(el.packageId) &&
                el.dishTypeId === item.id
            );
            item.dishList.forEach(el => {
              el.supplyTimeStart = this.selectedDate + (item.supplyTimeStart ? ' ' + item.supplyTimeStart.slice(0, 6) + '00' : '');
              el.supplyTimeEnd = this.selectedDate + (item.supplyTimeEnd ? ' ' + item.supplyTimeEnd.slice(0, 6) + '59' : '');
              el.ycsdText = item.ycsdText;
              el.kdsdStart = item.kdsdStart;
              el.kdsdEnd = item.kdsdEnd;
              el.ygdcsdText = item.ygdcsdText;
              el.typeName = item.typeName;
            });
            item.packList.forEach(el => {
              el.supplyTimeStart = this.selectedDate + (item.supplyTimeStart ? ' ' + item.supplyTimeStart.slice(0, 6) + '00' : '');
              el.supplyTimeEnd = this.selectedDate + (item.supplyTimeEnd ? ' ' + item.supplyTimeEnd.slice(0, 6) + '59' : '');
              el.ycsdText = item.ycsdText;
              el.kdsdStart = item.kdsdStart;
              el.kdsdEnd = item.kdsdEnd;
              el.ygdcsdText = item.ygdcsdText;
              el.typeName = item.typeName;
            });
          });
          // typRes.data = typRes.data.filter(item => item.ygdcsdText !== '已过订餐时段');
          if (this.firstInit) {
            this.firstInit = false;
            const flag = typRes.data.some(item => {
              return (
                !item.ygdcsdText &&
                ((Array.isArray(item.dishList) && item.dishList.length > 0) || (Array.isArray(item.packList) && item.packList.length > 0))
              );
            });
            if (flag) {
              this.selectedType = (typRes.data[0] || {}).id;
              Object.assign(this.selectedValue, typRes.data[0] || {});
              this.dishList = (typRes.data[0] || {}).dishList || [];
              this.packList = (typRes.data[0] || {}).packList || [];
              this.typeList = typRes.data;
              this.$toast.clear();
              this.startSetInterval();
            } else {
              this.selectedDate = moment().add(1, 'd').format('YYYY-MM-DD');
              this.$nextTick(() => {
                this.$refs.date_select.getWeeksList(this.selectedDate);
              })
              this.getList();
            }
          } else {
            this.selectedType = (typRes.data[0] || {}).id;
            Object.assign(this.selectedValue, typRes.data[0] || {});
            this.dishList = (typRes.data[0] || {}).dishList || [];
            this.packList = (typRes.data[0] || {}).packList || [];
            this.typeList = typRes.data;
            this.$toast.clear();
            this.startSetInterval();
          }
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
    startSetInterval() {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(this.updateListYgdcsdText, 1000);
    },
    updateListYgdcsdText() {
      this.currentTime = new Date();
      this.typeList.forEach(item => {
        if (item.kdsdStart && item.kdsdEnd) {
          if (this.currentTime < new Date(item.kdsdStart)) {
            item.ygdcsdText = '未到订餐时段';
          } else if (this.currentTime > new Date(item.kdsdEnd)) {
            item.ygdcsdText = '已过订餐时段';
          } else {
            item.ygdcsdText = '';
          }
        } else {
          item.ygdcsdText = '';
        }
        item.dishList.forEach(el => {
          el.ygdcsdText = item.ygdcsdText;
        });
        item.packList.forEach(el => {
          el.ygdcsdText = item.ygdcsdText;
        });
      });
    },
    typeOnClick(value, index) {
      Object.assign(this.selectedValue, value);
      this.selectedType = value.id;
      this.dishList = value.dishList || [];
      this.packList = value.packList || [];
    },
    seledDateOnChange(value) {
      this.selectedValue.kdsdText = '';
      this.dinCarList = [];
      this.getList();
    },
    dinCarOnClick() {
      if (this.dinCarList.length > 0) {
        this.popupShow = !this.popupShow;
      } else {
        this.popupShow = false;
      }
    },
    clearDinCarOnClick() {
      this.dinCarList = [];
      this.typeList.forEach(item => {
        item.dishList.forEach(el => {
          el.quantity = 0;
        });
        item.packList.forEach(el => {
          el.quantity = 0;
        });
      });
      this.popupShow = false;
    },
    clearSxDish() {
      this.typeList.forEach(item => {
        if (item.ygdcsdText) {
          item.dishList.forEach(el => {
            el.quantity = 0;
          });
          item.packList.forEach(el => {
            el.quantity = 0;
          });
        }
      });
      this.quantityOnChange();
    },
    nowDownOrderOnClick() {
      if (this.dinCarList.filter(item => !item.ygdcsdText).length === 0) {
        this.$toast({ message: '请先添加有效餐品之后在下单' });
        return;
      }
      sessionStorage.setItem(
        'jingting_dining_car_list',
        JSON.stringify(this.$_.cloneDeep(this.dinCarList.filter(item => !item.ygdcsdText)))
      );
      sessionStorage.setItem('jingting_dining_car_time', this.selectedDate);
      this.$router.push({
        name: 'eldCanOrderPayment'
      });
    },
    quantityOnChange(quantity, valueObj) {
      this.dinCarList = [];
      this.typeList
        .map(item => item.dishList.concat(item.packList))
        .flat()
        .forEach(item => {
          if (item.quantity > 0) {
            this.dinCarList.push(item);
          }
        });
    },
    myOrderOnClick() {
      this.$router.push({
        name: 'eldCanMyOrderList'
      });
    }
  }
};
</script>

<style lang="less" scoped>
.place-order {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-bottom: 158px;
  overflow-x: hidden;
  overflow-y: auto;
}
.header-info-box {
  box-sizing: border-box;
  height: 182px;
  background-image: url('./img/header_bg.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 0px 66px;
  .sq-logo {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    margin-right: 20px;
  }
  .text-info {
    box-sizing: border-box;
    flex-grow: 1;
    color: #ffffff;
    .sq-title {
      font-size: 32px;
      font-weight: 700;
      line-height: 44px;
    }
    .sub-text {
      font-size: 24px;
      line-height: 34px;
      margin-top: 10px;
    }
  }
}
.type-dish-pac-box {
  box-sizing: border-box;
  height: calc(100% - 182px - 140px);
  display: flex;
  .type-box {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 180px;
    height: 100%;
    background-color: #f5f5f5;
    .type-list {
      box-sizing: border-box;
      height: calc(100% - 160px);
      overflow-x: hidden;
      overflow-y: auto;
      .type-item {
        box-sizing: border-box;
        width: 100%;
        height: 156px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 10px;
        .type-name {
          box-sizing: border-box;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 28px;
          line-height: 32px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.5);
        }
        .ycsd-text {
          box-sizing: border-box;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.5);
          margin-top: 15px;
        }
        .ygdcsd-text {
          box-sizing: border-box;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
          color: rgba(51, 51, 51, 0.48);
          margin-top: 15px;
        }
        &.active {
          background-color: #ffffff;
          .type-name {
            color: #333333;
            font-weight: 700;
          }
          .ycsd-text {
            color: #333333;
          }
          .ygdcsd-text {
            color: #d9021b;
          }
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .my-order-btn {
      box-sizing: border-box;
      margin: 20px auto;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: url('./img/my_order.png') no-repeat 50% 50%;
      background-size: 100% 100%;
      overflow: hidden;
    }
  }
  .dish-pack-box {
    box-sizing: border-box;
    width: calc(100% - 180px);
    flex-grow: 1;
    .order-food-time {
      margin: 20px;
      box-sizing: border-box;
      background: linear-gradient(90deg, #ffedeb 0%, #fff9f9 100%);
      border-radius: 8px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      .dcicon {
        box-sizing: border-box;
        flex-shrink: 0;
        height: 36px;
        margin-right: 14px;
      }
      .label-text {
        color: #333333;
      }
      .value-text {
        color: #d9021b;
      }
    }
    .dish-pack-list {
      box-sizing: border-box;
      height: calc(100% - 92px);
      margin-top: 20px;
      padding: 0px 20px 20px 20px;
      overflow-x: hidden;
      overflow-y: auto;
      .left-right-line-text {
        margin-bottom: 15px;
      }
      .dish-info {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f7f7f7;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.footer-box {
  box-sizing: border-box;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  height: 158px;
  height: calc(158px + constant(safe-area-inset-bottom));
  height: calc(158px + env(safe-area-inset-bottom));
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 44px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 99;
  background-color: #ffffff;
  .dining-car {
    box-sizing: border-box;
    padding-right: 20px;
    display: flex;
    align-items: center;
    .din-car-img {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 88px;
      height: 104px;
      margin-right: 20px;
      position: relative;
      background: url('./img/meal_bag.png') no-repeat 50% 50%;
      background-size: cover;
      .img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .din-car-number {
        box-sizing: border-box;
        position: absolute;
        right: -10px;
        top: -10px;
        min-width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #fe3c00;
        color: #ffffff;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
      }
      &.dis {
        background-image: url('./img/meal_bag_dis.png');
      }
    }
    .price-amount-total {
      flex-shrink: 0;
      .price-text {
        font-size: 40px;
        line-height: 44px;
        color: #e02020;
        font-weight: 700;
      }
      .amount-text {
        font-size: 24px;
        color: #818181;
        .num-text {
          font-size: 36px;
          color: #f80000;
          font-weight: 700;
        }
      }
    }
  }
  .now-down-order-btn {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 256px;
    height: 96px;
    border-radius: 48px;
    color: #ffffff;
    font-size: 32px;
    line-height: 36px;
    background: linear-gradient(180deg, #fdc870 0%, #faac3b 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    &.dis {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      pointer-events: none;
    }
  }
}

.din-car-popup {
  box-sizing: border-box;
  height: 50%;
  padding-bottom: 158px;
  z-index: 98 !important;
  .popupt-title {
    box-sizing: border-box;
    height: 92px;
    border-bottom: 1px solid #f7f7f7;
    padding: 0px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-text {
      box-sizing: border-box;
      font-size: 32px;
      color: #333333;
      font-weight: 600;
      line-height: 36px;
    }
    .clear-text {
      box-sizing: border-box;
      font-size: 24px;
      color: #666666;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      align-items: center;
    }
  }
  .selected-dish-pac-box {
    box-sizing: border-box;
    height: calc(100% - 92px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: calc(20px + constant(safe-area-inset-bottom));
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    background-color: #f7f7f7;
    .selected-dish-pac-list {
      box-sizing: border-box;
      padding: 20px 40px;
      background-color: #ffffff;
      .dish-info {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .sxcp-box {
      box-sizing: border-box;
      margin-top: 20px;
      .sxcp-title {
        box-sizing: border-box;
        height: 92px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        padding: 0px 40px;
        .title-text {
          box-sizing: border-box;
          font-size: 32px;
          color: #333333;
          font-weight: 600;
          line-height: 36px;
        }
        .clear-text {
          box-sizing: border-box;
          font-size: 24px;
          color: #666666;
          font-weight: 600;
          line-height: 28px;
          display: flex;
          align-items: center;
        }
      }
      .sxcp-list {
        padding: 20px 40px;
      }
    }
  }
}
</style>
