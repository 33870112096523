<template>
  <div class="dish-info" :class="{ 'dish-info-small': size === 'small', sxzs: loseShow }">
    <div class="dish-img">
      <img v-if="detail.imageUrl" class="img" :src="detail.imageUrl" />
      <img v-else class="img" src="../img/zwtp.png" />
      <div class="mrxg-text" v-if="!['', void 0, null, NaN, '-1', -1].includes(detail.perCount)">
        每人限购 {{ ['', void 0, null, NaN, '-1', -1].includes(detail.perCount) ? '不限购' : detail.perCount }} 份
      </div>
    </div>
    <div class="text-info">
      <div class="name-text">{{ (detail.dishId ? detail.dishName : detail.packageName) || '-' }}</div>
      <div class="sales-volume" v-if="[0, 2].includes(detail.saleStatus)">已售罄</div>
      <div class="sales-volume" v-else>
        销量&nbsp;{{ detail.totalCount || 0 }}
        <span v-if="!['', void 0, null, NaN, '-1', -1].includes(detail.setTotalCount)">&nbsp;&nbsp;&nbsp;&nbsp;库存&nbsp;{{ kuCun }}</span>
      </div>
      <div class="price-text"><span class="fh">¥</span>{{ detail.price }}</div>
    </div>
    <div class="quantity-handle">
      <div v-if="!([0, 2].includes(detail.saleStatus) || detail.ygdcsdText)" class="btn minus-btn" @click="minusOnClick"></div>
      <div v-if="!([0, 2].includes(detail.saleStatus) || detail.ygdcsdText)" class="quantity-text">{{ quantity }}</div>
      <div class="btn add-btn" :class="{ dis: [0, 2].includes(detail.saleStatus) || detail.ygdcsdText }" @click="addOnClick"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dishInfo',
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    quantity: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'normal' // small
    },
    loseShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    kuCun() {
      const result = ['', void 0, null, NaN, '-1', -1].includes(this.detail.setTotalCount)
        ? Infinity
        : this.detail.setTotalCount - this.detail.totalCount;
      return result >= 0 ? result : 0;
    }
  },
  watch: {},
  created() {},
  methods: {
    minusOnClick() {
      if (this.quantity > 0) {
        const quantity = this.quantity - 1;
        this.$emit('update:quantity', quantity);
        this.$emit('quantity-change', quantity, this.detail);
      }
    },
    addOnClick() {
      if ([0, 2].includes(this.detail.saleStatus) || this.detail.ygdcsdText) return;
      const nullList = ['', void 0, null, NaN, '-1', -1];
      const kuCun = nullList.includes(this.detail.setTotalCount) ? Infinity : this.kuCun; // 库存
      const perCount = nullList.includes(this.detail.perCount) ? Infinity : this.detail.perCount; // 个人限量
      const maxQuantity = kuCun > perCount ? perCount : kuCun;
      if (this.quantity < maxQuantity) {
        const quantity = this.quantity + 1;
        this.$emit('update:quantity', quantity);
        this.$emit('quantity-change', quantity, this.detail);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dish-info {
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  .dish-img {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    .img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
    .mrxg-text {
      box-sizing: border-box;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 48px;
      border-radius: 0px 0px 10px 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffbe00;
      font-size: 24px;
      text-align: center;
      line-height: 48px;
    }
  }
  .text-info {
    box-sizing: border-box;
    flex-grow: 1;
    width: calc(100% - 200px);
    .name-text {
      box-sizing: border-box;
      width: 100%;
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 5px;
    }
    .sales-volume {
      font-size: 24px;
      line-height: 28px;
      color: #818181;
      margin-top: 38px;
    }
    .price-text {
      font-size: 32px;
      line-height: 36px;
      font-weight: 700;
      color: #e02020;
      margin-top: 38px;
      .fh {
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }
  .quantity-handle {
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    bottom: 10px;
    display: flex;
    align-items: center;
    .btn {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 100% 100%;
      &.minus-btn {
        background-image: url('../img/minus_btn.png');
      }
      &.add-btn {
        background-image: url('../img/add_btn.png');
        &.dis {
          background-image: url('../img/add_btn_dis.png');
          pointer-events: none;
        }
      }
    }
    .quantity-text {
      width: 80px;
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &.dish-info-small {
    .dish-img {
      width: 100px;
      height: 100px;
      .mrxg-text {
        display: none;
      }
    }
    .text-info {
      .sales-volume {
        margin-top: 5px;
      }
      .price-text {
        margin-top: 5px;
      }
    }
    .quantity-handle {
      bottom: 5px;
    }
  }
  &.sxzs {
    opacity: 0.3;
    .text-info {
      .name-text {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .price-text {
        color: rgba(0, 0, 0, 0.5);
        font-weight: 400;
      }
    }
  }
}
</style>
